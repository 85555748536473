import type { ItemModel, DataItemModel } from 'o365-dataobject';

import { DataObject, DataObjectExtension } from 'o365-dataobject';
import { localStorageHelper as localStorage } from 'o365-modules';
import { watch } from 'vue';

declare module 'o365-dataobject' {
    interface DataObject<T> {
        pagedData: PagedData<T>;
        hasPagedData: boolean;
    }
}

Object.defineProperties(DataObject.prototype, {
    'pagedData': {
        get() {
            if (this._pagedData == null) {
                this._pagedData = new PagedData(this, this._getExtensionCallback());
                this._pagedData.initialize();
            }
            return this._pagedData;
        }
    },
    'hasPagedData': {
        get() {
            return !!this._pagedData;
        }
    }
});

/**
 * DataObject extension for displaying and loading data in pages
 */
export default class PagedData<T extends ItemModel = ItemModel> extends DataObjectExtension<T> {
    private _dataObject: DataObject<T>;
    private _enabled = false;
    private _originalMaxRecords: number;

    private _initialPageSize!: number;
    private _userPageSize?: number;

    private _currentPage = 0;
    private _cancelTokens: (() => void)[] = [];
    private _updated = new Date();

    private _disableStoragePointer = false;

    /** Filtered items to the current page */
    private _data: DataItemModel<T>[] = [];

    /** Local storage key for user defined page size */
    get pageSizeLocalStorageKey() {
        return `${this._dataObject.id}_pagedData_pageSize`;
    }

    get enabled() { return this._enabled; }
    /** Initial page size (maxRecords) */
    get initialPageSize() { return this._initialPageSize; }
    /** User set page size */
    get userPageSize() { return this._userPageSize; }
    /** Current page size */
    get pageSize() { return +(this._userPageSize ?? this._initialPageSize); }
    set pageSize(pValue: number) {
        let cleanValue = +pValue <= 0
            ? 1
            : pValue > 500
                ? 500
                : pValue;

        if (pValue === this._initialPageSize) {
            this.storeUserPageSizePreference(null);
            this._userPageSize = undefined;
            this._dataObject.recordSource.maxRecords = this._initialPageSize;
        } else {
            this.storeUserPageSizePreference(cleanValue);
            this._userPageSize = cleanValue;
            this._dataObject.recordSource.maxRecords = Math.max(cleanValue, this._initialPageSize);

        }
        this._dataObject.load();
    }

    /** Current page index */
    get currentPage() { return this._currentPage; }
    set currentPage(pValue) {
        this._currentPage = pValue;
        this.update();
    }

    /** Index of the first item on the current page */
    get currentIndex() {
        return this.currentPage * this.pageSize;
    }
    /** Index of the last item on the current page */
    get lastIndex() {
        let lastPossibleIndex = this.currentIndex + this.pageSize - 1;
        if (this.rowCountIsLoaded) {
            return lastPossibleIndex > this._dataObject.rowCount! - 1
                ? this._dataObject.rowCount! - 1
                : lastPossibleIndex;
        } else {
            return lastPossibleIndex
        }
    }

    get isFirstPage() {
        return this._currentPage === 0;
    }
    get isLastPage() {
        return this.lastPage != null && this.lastPage === this.currentPage;
    }

    get rowCountIsLoaded() {
        return this._dataObject.rowCount != null && this._dataObject.rowCount != -1;
    }

    /** Index of the last possible page */
    get lastPage() {
        if (this.rowCountIsLoaded) {
            return Math.floor((this._dataObject.rowCount! - 1) / this.pageSize);
        } else {
            return undefined;
        }
    }

    /** Current page data array */
    get data() {
        return this._data;
    }

    /** Indicates that either next page or entire dataobject is currently loading */
    get isLoading() {
        return this._dataObject.state.isLoading || this._dataObject.state.isNextPageLoading;
    }

    /** When true will skip the dataObject.data override */
    get disableStoragePointer() { return this._disableStoragePointer; }
    set disableStoragePointer(pValue) { this._disableStoragePointer = pValue; }

    get updated() { return this._updated; }

    constructor(pDataObject: DataObject<T>, ...args: ConstructorParameters<typeof DataObjectExtension<T>>) {
        super(...args);
        this._dataObject = pDataObject;
        this._originalMaxRecords = pDataObject.recordSource.maxRecords;
    }

    initialize() {
        this._initialPageSize = +this._dataObject.dynamicLoading.pageSize;
        this._userPageSize = this.getStoredUserPageSizePreference() ?? undefined;
        if (this._userPageSize != null) {
            this._dataObject.recordSource.maxRecords = Math.max(this._userPageSize, this._initialPageSize);
        }
    }

    enable(pOptions?: {
        initialPageSize?: number
    }) {
        if (this._enabled) { return; }
        if (pOptions?.initialPageSize) {
            this._initialPageSize = +pOptions.initialPageSize;
        }
        const shouldUpdate = this._dataObject.state.isLoaded && this._dataObject.data.length > 0;
        let shouldLoad = false;
        if (!this._disableStoragePointer) {
            this._dataObject.setStoragePointer(this._data);
        }
        if (shouldUpdate) {
            this.update();
            if (!this.rowCountIsLoaded && this._data.length < this.pageSize) {
                shouldLoad = true;
            }
        } else if (this._dataObject.state.isLoading && this._dataObject.dataHandler.getAbortControllerForRequest) {
            const options = this._dataObject.recordSource.getOptions();
            options.maxRecords = this._originalMaxRecords;
            if (options.maxRecords && this._isSmaller(options.maxRecords, this.pageSize)) {
                const abortController = this._dataObject.dataHandler.getAbortControllerForRequest({...options, operation: 'retrieve'});
                if (abortController) {
                    abortController.abort('DataObject is being loaded with less records than the current page size while enabling PagedData extension. Redoing the request with new page size');
                    shouldLoad = true;
                }
            }
        }

        this._executeOnDataObject('overrideEmit', 'DataLoaded', (pData, pOptions) => {
            if (pOptions.skip === 0) {
                this.currentPage = 0;
            }
            this.update();
            this._dataObject.eventHandler.emit('DataLoaded', pData, pOptions);
        });
        this._cancelTokens.push(() => {
            this._executeOnDataObject('clearEmitOverride', 'DataLoaded');
        });

        // this._cancelTokens.push(this._dataObject.on('DataLoaded', (_pData, pOptions) => {
        //     if (pOptions.skip === 0) {
        //         this.currentPage = 0;
        //     }
        //     this.update();
        // }));
        if (!this._dataObject.dynamicLoading.enabled) {
            this._dataObject.dynamicLoading.enabled = true;
            this._dataObject.dynamicLoading.dataLoaded(this._dataObject.storage.data);
        }
        this._cancelTokens.push(watch(() => this._dataObject.storage.updated, () => {
            this.update();
        }));

        this._enabled = true;
        if (shouldLoad) {
            this.goToPage(0);
        }
    }

    disable() {
        if (!this._enabled) { return; }
        if (!this._disableStoragePointer) {
            this._dataObject.setStoragePointer(undefined);
        }
        this._cancelTokens.splice(0, this._cancelTokens.length).forEach(ct => ct());
        this._enabled = false;
    }

    /** Update the dataObject.data with the current page filtered items */
    update() {
        const currentIndex = this._currentPage * this.pageSize;
        this._data.splice(0, this._data.length, ...this._dataObject.storage.data.slice(currentIndex, currentIndex + this.pageSize));
        this._updated = new Date();
    }

    /**
     * Go to next page
     * Will initiate a load if there are missing items
     */
    async next() {
        if (this.isLastPage) { return; }
        await this._dataObject.dynamicLoading.loadFromIndex(this.lastIndex + 1, this.pageSize);
        const wasLast = await this._checkForLastPage(this.currentPage + 1);
        if (wasLast) {
            this.currentPage = this.lastPage!;
        } else {
            this.currentPage = this.currentPage + 1;
        }
    }

    /**
     * Go to previous page
     * Will initiate a load if there are missing items
     */
    async previous() {
        if (this.isFirstPage) { return; }
        await this._dataObject.dynamicLoading.loadFromIndex(this.currentIndex - this.pageSize, this.pageSize);
        this.currentPage = this.currentPage - 1;
    }


    /**
     * Go to the given page
     * Will initiate a load if there are missing items
     */
    async goToPage(pPage: number) {
        await this._dataObject.dynamicLoading.loadFromIndex(pPage * this.pageSize, this.pageSize);
        const wasLast = await this._checkForLastPage(pPage);
        if (wasLast) {
            this.currentPage = this.lastPage!;
        } else {
            this.currentPage = pPage;
        }
    }

    /**
     * Go to the last page, will load row count first if it's not loaded yet.
     * Will initiate a load if there are missing items
     */
    async goToLastPage() {
        if (this._dataObject.state.rowCount == null) {
            await this._dataObject.recordSource.loadRowCount();
        }
        if (this.lastPage != null) {
            return this.goToPage(this.lastPage)
        }
    }

    storeUserPageSizePreference(pValue: number | null) {
        if (!this._dataObject.metadata?.isFromDesigner) { return; } // Non appdesigner dataobjects not supported due to dynamic ids
        if (pValue == null) {
            localStorage.removeItem(this.pageSizeLocalStorageKey);
        } else {
            localStorage.setItem(this.pageSizeLocalStorageKey, `${pValue}`);
        }
    }

    getStoredUserPageSizePreference() {
        try {
            const pageSize = localStorage.getItem(this.pageSizeLocalStorageKey);
            if (pageSize) {
                const parsedPageSize = +pageSize;
                if (parsedPageSize <= 0) {
                    return 1;
                } else if (parsedPageSize > 500) {
                    return 500;
                } else {
                    return +parsedPageSize;
                }
            } else {
                return null;
            }
        } catch (ex) {
            return null;
        }
    }

    private async _checkForLastPage(pNewPage: number) {
        if (this.rowCountIsLoaded && pNewPage > this.lastPage!) {
            await this.previous();
            return true;
        }
        return false;
    }

    private _isSmaller(a: number, b: number) {
        if (a === b) {
            return false;
        } else if (a === -1 || b === -1) {
            return b === -1;
        } else {
            return a < b;
        }
    }
}